import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AboutSection from "../../components/About/AboutSection";
import PageHeader from "../../components/About/PageHeader";
import FactSection from "../../components/Facts.js/FactSection";
import TeamSection from "../../components/Team/TeamSection";

export default function About() {
  return (
    <>
      <Header />
      <PageHeader />
      <AboutSection />
      <FactSection />
      <TeamSection />
      <Footer />
    </>
  );
}
