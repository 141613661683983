import React from "react";
import { Link } from "react-router-dom";
import CardData from "../../utlis/CardData";
import ServiceItem from "./ServiceItem";


export default function ServiceSection() {
    return (
        <>
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                        <p class="fs-5 fw-bold text-primary">Our Services</p>
                        <h1 class="display-5 mb-5">Services That We Offer You</h1>
                    </div>
                    <div className="row g-4">
                        {CardData.map(service => (
                            <ServiceItem key={service.id} service={service} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}