import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/Projects/PageHeader";
import ProjectSection from "../../components/Projects/ProjectSection";

export default function Project(){
    return(
        <>
            <Header />
            <PageHeader />
            <ProjectSection />
            <Footer />
        </>
    )
}