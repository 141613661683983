const QuoteData = [
    {
        id:1,
        heading:'Member',
        description:'Become a member to protect lives on the Streets.',
        icon:'/img/icon/form.png',
        img: '/img/animalfeederslogo.jpeg',
        // link:'/member-form',
        link: "https://docs.google.com/forms/d/e/1FAIpQLScETY0PK-E0etr5T0XHEduhF9StcjBoVn-vAaXYViaUweeZfA/viewform",
        delay:'0.1s'
    },
    {
        id:2,
        heading:'Volunteer',
        description:'Alone we can do so little; together we can do so much.',
        icon:'/img/icon/form.png',
        img: '/img/animalfeederslogo.jpeg',
        link:'https://docs.google.com/forms/d/e/1FAIpQLSd8JI4hi92IY6-HKaDQ5kYPIIUbsvx6NzNyXhQATkrgbCvloQ/viewform',
        delay:'0.2s'
    },
    {
        id:3,
        heading:'Contributor',
        description:'It\'s not how much we give but how much love we put into giving.',
        icon:'/img/icon/form.png',
        img: '/img/animalfeederslogo.jpeg',
        link:'/donate',
        delay:'0.3s'
    }
]

export default QuoteData;