import React from "react";
import { Link } from "react-router-dom";
import ProjectCardData from "../../utlis/ProjectCardData";
import ProjectCardItem from './ProjectCardItem';

export default function ProjectSection() {
    return (
        <>
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                        <p class="fs-5 fw-bold text-primary">Our Projects</p>
                        <h1 class="display-5 mb-5">Some Of Our Wonderful Projects</h1>
                    </div>
                    <div class="row g-4 portfolio-container">
                        {ProjectCardData.map((item) =>
                            <ProjectCardItem key={item.id} item={item} />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}