import React from "react";
import { Link } from "react-router-dom";

const ProjectCardItem = ({ item }) => {
    return (
        <div className={`col-lg-4 col-md-6 portfolio-item first wow fadeInUp`} data-wow-delay={item.delay}>
            <div className="portfolio-inner rounded">
                <img className="rounded mx-auto d-block" height={"350"} width={"355"} src={item.img} alt="" />
                <div className="portfolio-text">
                    <h4 className="text-white mb-4">{item.content}</h4>
                    <div className="d-flex">
                        <a className="btn btn-lg-square rounded-circle mx-2" href={item.btnlink} data-lightbox="portfolio"><i className="fa fa-eye"></i></a>
                        {/* <Link className="btn btn-lg-square rounded-circle mx-2" to={item.link}><i class="fa fa-link"></i></Link> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectCardItem;