import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/Service/PageHeader";
import ServiceSection from "../../components/Service/ServiceSection";

export default function Services(){
    return(
        <>
            <Header />
            <PageHeader />
            <ServiceSection />
            <Footer />
        </>
    )
}