import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/Contact/PageHeader";
import ContactSection from "../../components/Contact/ContactSection";

export default function Contact() {
  return (
    <>
      <Header />
      <PageHeader />
      <ContactSection />
      <Footer />
    </>
  );
}
