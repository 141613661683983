import React from "react";

export default function CarouselSection() {
  return (
    <>
      {/* <div class="container-fluid top-feature py-5 pt-lg-0">
        <div class="container py-5 pt-lg-0">
          <div class="row gx-0">
            <div class="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
              <div
                class="bg-white shadow d-flex align-items-center h-100 px-5"
                style={{ minHeight: "160px" }}
              >
                <div class="d-flex">
                  <div class="flex-shrink-0 btn-lg-square rounded-circle bg-light">
                    <i class="fa fa-times text-primary"></i>
                  </div>
                  <div class="ps-3">
                    <h4>No Hidden Cost</h4>
                    <span>Clita erat ipsum lorem sit sed stet duo justo</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div
                class="bg-white shadow d-flex align-items-center h-100 px-5"
                style={{ minHeight: "160px" }}
              >
                <div class="d-flex">
                  <div class="flex-shrink-0 btn-lg-square rounded-circle bg-light">
                    <i class="fa fa-users text-primary"></i>
                  </div>
                  <div class="ps-3">
                    <h4>Dedicated Team</h4>
                    <span>Clita erat ipsum lorem sit sed stet duo justo</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div
                class="bg-white shadow d-flex align-items-center h-100 px-5"
                style={{ minHeight: "160px" }}
              >
                <div class="d-flex">
                  <div class="flex-shrink-0 btn-lg-square rounded-circle bg-light">
                    <i class="fa fa-phone text-primary"></i>
                  </div>
                  <div class="ps-3">
                    <h4>24/7 Available</h4>
                    <span>Clita erat ipsum lorem sit sed stet duo justo</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
