import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import AboutSection from "../../components/About/AboutSection";
import FactSection from "../../components/Facts.js/FactSection";
// import FeatureSection from "../../components/Features/FeatureSection";
import ServiceSection from "../../components/Service/ServiceSection";
import QuoteSection from "../../components/Quote/QuoteSection";
import ProjectSection from "../../components/Projects/ProjectSection";
import TeamSection from "../../components/Team/TeamSection";
import TestimonialSection from "../../components/Testimonial/TestimonialSection";
import CarouselSection from "../../components/Carousel/CarouselSection";
import NavbarSection from "../../components/Navbar/NavbarSection";
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/css/bootstrap.css';
// import "react-bootstrap/dist/react-bootstrap.min.js"

export default function Home() {
  return (
    <>
      <Header />
      <NavbarSection />
      <CarouselSection />
      <AboutSection />
      <FactSection />
      {/* <FeatureSection /> */}
      <ServiceSection />
      <QuoteSection />
      <ProjectSection />
      <TeamSection />
      <TestimonialSection />
      <Footer />
    </>
  );
}
