import React from "react";
import { Link } from "react-router-dom";

const QuoteCardItem = ({ Quote }) => {
    return (
        <div class={`col-lg-4 col-md-6 wow fadeInUp`} data-wow-delay={Quote.delay}>
            <div class="service-item rounded d-flex h-100">
                <div class="service-img rounded">
                    <img class="img-fluid" src={Quote.img} alt="" />
                </div>
                <div class="service-text rounded p-5">
                    <div class="btn-square rounded-circle mx-auto mb-3">
                        <img class="img-fluid" src={Quote.icon} alt="Icon" />
                    </div>
                    <h4 class="mb-3">{Quote.heading}</h4>
                    <p class="mb-4">{Quote.description}</p>
                    <Link class="btn btn-lg" to={Quote.link}>Fill Form</Link>
                </div>
            </div>
        </div>
    );
};

export default QuoteCardItem;