import React from "react";
import TeamCardData from "../../utlis/TeamCardData";
import TeamCardItem from "./TeamCardItem";

export default function TeamSection() {
  return (
    <>
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <p className="fs-5 fw-bold text-primary">Our Team</p>
            <h1 className="display-5 mb-5">
              Dedicated & Experienced Team Members
            </h1>
          </div>
          <div className="row g-4">
            {TeamCardData.map((item) =>
              <TeamCardItem key={item.id} item={item} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
