import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/Testimonial/PageHeader";
import TestimonialSection from "../../components/Testimonial/TestimonialSection";

export default function Testimonial() {
  return (
    <>
      <Header />
      <PageHeader />
      <TestimonialSection />
      <Footer />
    </>
  );
}
