import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function Headers() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  useEffect(() => {
    const updateNavbar = () => {
      if (window.innerWidth > 991) {
        setIsNavCollapsed(true);
      }
    };

    window.addEventListener("resize", updateNavbar);
    return () => window.removeEventListener("resize", updateNavbar);
  }, []);

  return (
    <>
      <div className="container-fluid bg-dark text-light px-0 py-2">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <span className="fa fa-phone-alt me-2"></span>
              <span>+918266809969</span>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <Link
                className="btn btn-link text-light"
                to="https://www.gmail.com"
              >
                <span className="far fa-envelope me-2"></span>
                <span>animalfeedersteam@gmail.com</span>
              </Link>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <span>Follow Us:</span>
              <Link
                className="btn btn-link text-light"
                to="https://www.facebook.com/animalsfeeder/"
              >
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link
                className="btn btn-link text-light"
                to="https://twitter.com/FeedersOfAnimal/"
              >
                <i className="fab fa-twitter"></i>
              </Link>
              <Link
                className="btn btn-link text-light"
                to="https://in.linkedin.com/in/animal-feeders-012222212"
              >
                <i className="fab fa-linkedin-in"></i>
              </Link>
              <Link
                className="btn btn-link text-light"
                to="https://www.instagram.com/animal_feeders_/"
              >
                <i className="fab fa-instagram"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <Link to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
          <img src="img/animalfeederslogo.jpeg" alt="Animal Feeders Logo" height="30" />
          <h1 className="m-2">Animal Feeders</h1>
        </Link>
        <button
          className="navbar-toggler me-4"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            {[
              { title: "Home", path: "" },
              { title: "About", path: "about" },
              { title: "Services", path: "services" },
              { title: "Projects", path: "projects" },
            ].map((nav, index) => (
              <Link key={index} className="nav-item nav-link" to={`/${nav.path}`}>
                {nav.title}
              </Link>
            ))}
            <div className="nav-item dropdown">
              <Link
                to="#"
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
              >
                Pages
              </Link>
              <div className="dropdown-menu bg-light m-0">
                {[
                  { title: "Join Us", path: "join-us" },
                  { title: "Our Team", path: "team" },
                  { title: "Our Supporters", path: "our-supporters" },
                ].map((nav, index) => (
                  <Link key={index} className="dropdown-item" to={`/${nav.path}`}>
                    {nav.title}
                  </Link>
                ))}
              </div>
            </div>
            <Link className="nav-item nav-link" to="/contact">
              Contact
            </Link>
          </div>
          <Link
            to="/donate"
            className="btn btn-primary py-4 px-lg-4 rounded-0 d-none d-lg-block"
          >
            Donate Us<i className="fa fa-arrow-right ms-3"></i>
          </Link>
        </div>
      </nav>
    </>
  );
}
