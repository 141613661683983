import React from "react";
export default function DonateUsSection() {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <p class="fs-5 fw-bold text-primary">Donate Us</p>
              <h1 class="display-5 mb-5">
                To help and support the needy, Please Donate Us
              </h1>
              <p class="mb-4">
                <b>You can donate us via many methods -</b>
              </p>

              <div class="row g-5 portfolio-container">
                <div
                  class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <h4>Paytm :</h4>
                  <br />
                  <h6> Phone No.- 8077176326</h6>
                  <h6>UPI ID - 8077176326@paytm</h6>
                  <div class="portfolio-inner rounded">
                    <img class="img-fluid" src="img/paytm3.jpeg" alt="" />
                    <div class="portfolio-text">
                      <h4 class="text-white mb-4">Paytm</h4>
                      <div class="d-flex">
                        <a
                          class="btn btn-lg-square rounded-circle mx-2"
                          href="img/paytm.jpeg"
                          data-lightbox="portfolio"
                        >
                          <i class="fa fa-eye"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <h4>Phone Pe :</h4>
                  <br />
                  <h6>Phone No.- 8077176326</h6>
                  <h6>UPI ID - 8077176326kotak@ybl</h6>
                  <div class="portfolio-inner rounded">
                    <img class="img-fluid" src="img/phonepe.jpeg" alt="" />
                    <div class="portfolio-text">
                      <h4 class="text-white mb-4">Phone Pe</h4>
                      <div class="d-flex">
                        <a
                          class="btn btn-lg-square rounded-circle mx-2"
                          href="img/phonepe.jpeg"
                          data-lightbox="portfolio"
                        >
                          <i class="fa fa-eye"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <h4>Google Pay :</h4>
                  <br />
                  <h6>Phone No.- 8077176326</h6>
                  <h6>UPI ID - mjnyash007@okaxis</h6>
                  <div class="portfolio-inner rounded">
                    <img class="img-fluid" src="img/gpay2.jpeg" alt="" />
                    <div class="portfolio-text">
                      <h4 class="text-white mb-4">Google Pay</h4>
                      <div class="d-flex">
                        <a
                          class="btn btn-lg-square rounded-circle mx-2"
                          href="img/gpay.jpeg"
                          data-lightbox="portfolio"
                        >
                          <i class="fa fa-eye"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
