import React from "react";
import { Link } from "react-router-dom";

export default function TestimonialSection() {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <p class="fs-5 fw-bold text-primary">Our Supporters</p>
              <h1 class="display-5 mb-5">What Our Supporters Say About Us!</h1>
              <p class="mb-4">
                We are really thankful to our supporters who are always there
                for us.
              </p>
              <Link class="btn btn-primary py-3 px-4" to="/">
                Coming soon
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
