const TeamCardData = [
    {
        id:1,
        name:'Er.Yash Mani Jain',
        designation:'Founder',
        img:'/img/yash.jpeg',
        link_1:'https://www.facebook.com/yash.manijain/',
        link_2:'https://in.linkedin.com/in/yash-mani-jain-b011b1219',
        link_3:'https://www.instagram.com/yash_mani_jain/'
    },
    {
        id:2,
        name:'Yukti Gupta',
        designation:'Board Member',
        img:'/img/yukti.jpeg',
        link_1:'',
        link_2:'',
        link_3:'https://instagram.com/yuktii_guptaa'
    },
    {
        id:3,
        name:'Gaurav Yadav',
        designation:'Board Member',
        img:'/img/gaurav.jpeg',
        link_1:'',
        link_2:'',
        link_3:''
    },
    {
        id:4,
        name:'Archie Gupta',
        designation:'Board Member',
        img:'/img/archie.jpeg',
        link_1:'https://www.linkedin.com/in/archiegupta121',
        link_2:'https://instagram.com/gupta.archie121?igshid=ZDdkNTZiNTM=',
        link_3:''
    },
    {
        id:5,
        name:'Shivam Sharma',
        designation:'Board Member',
        img:'/img/shivam.jpeg',
        link_1:'',
        link_2:'',
        link_3:''
    },
    {
        id:6,
        name:'Dr. Aditi Sharma',
        designation:'Board Member',
        img:'/img/aditi.jpg',
        link_1:'',
        link_2:'',
        link_3:''
    },
    {
        id:7,
        name:'Anuj Gupta',
        designation:'Board Member',
        img:'/img/anuj.jpg',
        link_1:'',
        link_2:'',
        link_3:''
    },
    {
        id:8,
        name:'Bharat Agrawal',
        designation:'Board Member',
        img:'/img/bharat.jpg',
        link_1:'',
        link_2:'',
        link_3:''
    }

    // {
    //     id:6,
    //     name:'Dr.Bhumika Maheshwari',
    //     designation:'Board Member',
    //     img:'/img/bhumika.jpeg',
    //     link_1:'https://twitter.com/thisisbhumika',
    //     link_2:'https://instagram.com/thisisbhumika_?igshid=YmMyMTA2M2Y=',
    //     link_3:''
    // },
    // {
    //     id:7,
    //     name:'Prachi Gupta',
    //     designation:'Board Member',
    //     img:'/img/prachi.jpeg',
    //     link_1:'',
    //     link_2:'',
    //     link_3:''
    // }
]

export default TeamCardData;