import React from "react";
import { Link } from "react-router-dom";

const TeamCardItem = ({ item }) => {
    return (
        <div className={`col-lg-3 col-md-6 wow fadeInUp`} data-wow-delay="0.3s">
            <div className="team-item rounded">
                <img className="rounded mx-auto d-block" src={item.img} alt="" height={"350"} width={"250"} />
                <div className="team-text">
                    <h4 className="mb-0">{item.name}</h4>
                    <p className="text-primary">{item.designation}</p>
                    <div className="team-social d-flex">
                        <Link className="btn btn-square rounded-circle me-2" to={item.link_1}>
                            <i className="fab fa-facebook-f"></i>
                        </Link>
                        <Link className="btn btn-square rounded-circle me-2" to={item.link_2}>
                            <i className="fab fa-linkedin"></i>
                        </Link>
                        <Link className="btn btn-square rounded-circle me-2" to={item.link_3}>
                            <i className="fab fa-instagram"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamCardItem;