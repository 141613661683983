import * as React from "react";
import { Link } from "react-router-dom";

export default function AboutSection() {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5 align-items-end">
            <div class="col-lg-3 col-md-5 wow fadeInUp" data-wow-delay="0.1s">
              <img
                class="img-fluid rounded"
                data-wow-delay="0.1s"
                src="img/about2.jpeg"
                alt="abc"
              />
            </div>
            <div class="col-lg-6 col-md-7 wow fadeInUp" data-wow-delay="0.3s">
              <h1 class="display-5 mb-4">We Feed The Needy</h1>
              <p class="mb-4">
                We feed the animals everyday like dogs, cows, monkeys, buffaloes
                etc as per the food they eat ,according to their need, we are
                the "ANIMAL FEEDERS". Plenty of organizations are working for
                the welfare of animals while animal feeders feed the animals
                while maintaining their ability to survive in the harsh
                conditions by giving them only the nutrients they needed and
                maintaining their habit of non-independency.
                <br /> While the animals are voiceless while actually they have
                a very talkative nature if you will understand their behaviour.
                There is a reason & meaning for every action they do either it
                can be for gaining attention or showing love or only a little
                help. <br />
                All are the <b>ANIMAL FEEDERS</b> who love and care the animals
                but we don't know about them, they are nook & corner, doing
                rigerously for them only for satisfaction.
                <br /> <br />
                <b>
                  "We are not just an organisation but a fragrance you may not
                  remember our name but may get our essence whenever you will
                  see an animal around you healthy & happy."{" "}
                </b>
              </p>
              <Link class="btn btn-primary py-3 px-4" to="/about">
                Explore More
              </Link>
            </div>
            <div class="col-lg-3 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
              <div class="row g-5">
                <div class="col-12 col-sm-6 col-lg-12">
                  <div class="border-start ps-4">
                    <i class="fa fa-award fa-3x text-primary mb-3"></i>
                    <h4 class="mb-3">Award Winning</h4>
                    <span>
                      We got many apreciation award for our work in the field of
                      Animal Welfare.
                    </span>
                  </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-12">
                  <div class="border-start ps-4">
                    <i class="fa fa-users fa-3x text-primary mb-3"></i>
                    <h4 class="mb-3">Dedicated Team</h4>
                    <span>
                      Our team is much dedicated for its work, they conisdered
                      it as their duty to feed and help the stray. Even we
                      (Animal Feeders) do not call ourself as team, we call it a
                      family, instead.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
