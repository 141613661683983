import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/Quote/PageHeader";
import QuoteSection from "../../components/Quote/QuoteSection";

export default function Quote() {
  return (
    <>
      <Header />
      <PageHeader />
      <QuoteSection />
      <Footer />
    </>
  );
}
