import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/Team/PageHeader";
import TeamSection from "../../components/Team/TeamSection";

export default function Team() {
  return (
    <>
      <Header />
      <PageHeader />
      <TeamSection />
      <Footer />
    </>
  );
}
