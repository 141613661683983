import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PageHeader from "../../components/DonateUs/PageHeader";
import DonateUsSection from "../../components/DonateUs/DonateUsSection";

export default function DonateUs() {
  return (
    <>
      <Header />
      <PageHeader />
      <DonateUsSection />
      <Footer />
    </>
  );
}
