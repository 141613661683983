import React from 'react';
import  ReactDOM  from 'react-dom';
import App from './App';

// import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import 'jquery/dist/jquery.min.js';
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/js/bootstrap.bundle';
// import 'bootstrap/dist/css/bootstrap.css'

ReactDOM.render(<App />, document.getElementById('root'))