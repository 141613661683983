import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div
        className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h4 className="text-white mb-4">Our Office</h4>
              {/* <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>123 Street, New
                York, USA
              </p> */}
              
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>+918266809969
              </p>
             
              <Link className="btn-link text-light" to="https://www.gmail.com"> 
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>
                animalfeedersteam@gmail.com
              </p>
              </Link>
              <div className="d-flex pt-2">
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  to="https://twitter.com/FeedersOfAnimal/"
                >
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  to="https://www.facebook.com/animalsfeeder/"
                >
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  to="https://www.instagram.com/animal_feeders_/"
                >
                  <i className="fab fa-instagram"></i>
                </Link>
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  to="https://in.linkedin.com/in/animal-feeders-012222212"
                >
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Quick Links</h4>
              <Link className="btn btn-link" to="/about">
                About Us
              </Link>
              <Link className="btn btn-link" to="/contact">
                Contact Us
              </Link>
              <Link className="btn btn-link" to="/services">
                Our Services
              </Link>
            </div>
            <div className="col-lg-3 col-md-9">
            <img src="img/animalfeeders.jpeg" alt=""  style={{ alignSelf: 'center' }} height={180} /><br /><br /> 
              <h4 className="text-white mb-4">Thank You, Visitor</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <Link className="border-bottom" to="#">
                animalfeeders.in
              </Link>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end"></div>
          </div>
        </div>
      </div>
    </>
  );
}
