import React from "react";
import { Link, useLocation } from "react-router-dom";
import PageHeader from "./MemberForm/PageHeader";
import "./SubmitForm.css";

export default function SubmitForm() {
  const location = useLocation();
  return (
    <>
      <PageHeader title={location.state?.pageTitle} subTitle= {location.state?.pageSubTitle} />
      <div className="submitContainer">
        <div class="wow fadeInUp" data-wow-delay="0.1s">
          <h1 className="submitContent">
            Congrats! Your form has been submitted successfully.
          </h1>
          <Link to="/">
            {" "}
            <button className="backButton">Back to Home</button>{" "}
          </Link>
        </div>
      </div>
    </>
  );
}
