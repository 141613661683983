import React from "react";
import { Link } from "react-router-dom";

const ServiceItem = ({ service }) => {
    return (
        <div className={`col-lg-4 col-md-6 wow fadeInUp`} data-wow-delay={service.delay}>
            <div className="service-item rounded d-flex h-100">
                <div className="service-img rounded">
                    <img className="img-fluid" src={service.img} alt='' />
                </div>
                <div className="service-text rounded p-5">
                    <div className="btn-square rounded-circle mx-auto mb-3">
                        <img className="img-fluid" src={service.icon} alt="Icon" />
                    </div>
                    <h4 className="mb-3">{service.title}</h4>
                    <p className="mb-4">{service.description}</p>
                    {/* <Link className="btn btn-sm" to={service.link}><i className="fa fa-plus text-primary me-2"></i>Read More</Link> */}
                </div>
            </div>
        </div>
    );
};

export default ServiceItem;