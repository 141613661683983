const ProjectCardData = [
    {
        id:1,
        img:'/img/feeders-all-over.jpeg',
        content:'Feeders All Over',
        btnlink:'/img/feeders-all-over.jpeg',
        link:"",
        delay:'0.1s'
    },
    {
        id:2,
        img:'/img/vaccination.jpeg',
        content:'Anti-Rabies Vaccination Camp',
        btnlink:'img/vaccination.jpeg',
        link:"",
        delay:'0.2s'
    },
    {
        id:3,
        img:'/img/lumpy-camp.jpeg',
        content:'Lumpy Virus Vaccination Camp',
        btnlink:'/img/lumpy-camp.jpeg',
        link:"",
        delay:'0.3s'
    },
    {
        id:4,
        img:'/img/reflecting.jpeg',
        content:'Reflecting Collars Camp',
        btnlink:'/img/reflecting.jpeg',
        link:"",
        delay:'0.4s'
    },
    {
        id:5,
        img:'/img/awareness.jpeg',
        content:'Awareness Camp',
        btnlink:'/img/awareness.jpeg',
        link:"",
        delay:'0.5s'
    },
    {
        id:6,
        img:'/img/feed-bird.jpeg',
        content:'Feed The Birds Campaign',
        btnlink:'/img/feed-bird.jpeg',
        link:"",
        delay:'0.1s'
    }
]

export default ProjectCardData;