import React from "react";
import QuoteData from "../../utlis/QuoteData";
import QuoteCardItem from './QuoteCardItem';


export default function QuoteSection() {
    return (
        <>
            <div class="container-xxl py-5">
                <div class="container">
                    <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "500px" }}>
                        <p class="fs-5 fw-bold text-primary">Join Us</p>
                        <h1 class="display-5 mb-5">You can join us as - </h1>
                    </div>
                    <div class="row g-4">
                        {QuoteData.map(Quote => (
                            <QuoteCardItem key={Quote.id} Quote={Quote} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}