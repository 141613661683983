const CardData = [
    {
        id: 1,
        title: "Animal Feeding",
        description: `Animal Feeders has undertaken the initiative to provide nutritious food to stray and abandoned animals. Regular feeding drives are conducted to ensure that these animals receive the sustenance they need, reducing hunger and improving their overall well-being.`,
        icon: '/img/icon/icon-feeding.png',
        img: '/img/animalfeederslogo.jpeg',
        link: "",
        delay: "0.1s"
    },
    {
        id: 2,
        title: "Animal Welfare",
        description: `Our mission is to promote the well-being of all animals through compassionate care and advocacy. We strive to protect animals from cruelty and neglect, ensuring they live in safe and nurturing environments.`,
        icon: '/img/icon/icon-welfare.png',
        img: '/img/animalfeederslogo.jpeg',
        link: "",
        delay: "0.2s"
    },
    {
        id: 3,
        title: "Vaccination Campus",
        description: `We organize vaccination camps to protect street animals from deadly diseases. By vaccinating these animals, we help control the spread of infections and contribute to a healthier, safer community for both animals and humans.`,
        icon: '/img/icon/icon-vaccine2.png',
        img: '/img/animalfeederslogo.jpeg',
        link: "",
        delay: "0.3s"
    },
    {
        id: 4,
        title: "Awareness Camps",
        description: `Education is key to creating a compassionate society. Our awareness camps aim to inform the public about the importance of animal welfare, responsible pet ownership, and how to coexist peacefully with street animals.`,
        icon: '/img/icon/icon-awareness.png',
        img: '/img/animalfeederslogo.jpeg',
        link: "",
        delay: "0.4s"
    },
    {
        id: 5,
        title: "Adoption",
        description: `We facilitate the adoption of homeless animals, giving them a chance to find loving, forever homes. Our adoption program matches pets with caring families, ensuring that each animal finds the perfect place to call home.`,
        icon: '/img/icon/icon-adoption.png',
        img: '/img/animalfeederslogo.jpeg',
        link: "",
        delay: "0.5s"
    },
    {
        id: 6,
        title: "First-AID Training Session",
        description: `Our first-aid training sessions equip volunteers and animal lovers with the skills needed to provide immediate care to injured animals. Participants learn essential techniques to help save lives and alleviate suffering in emergency situations.`,
        icon: '/img/icon/icon-first-aid.png',
        img: '/img/animalfeederslogo.jpeg',
        link: "",
        delay: "0.1s"
    }
]

export default CardData;