import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Project from "./pages/Projects/Project";
// import Feature from "./pages/Feature/Feature";
import Quote from "./pages/Quote/Quote";
import Team from "./pages/Team/Team";
import Testimonial from "./pages/Testimonial/Testimonial";
// import Page404 from "./pages/404Page/404Page";
import Contact from "./pages/Contact/Contact";
import DonateUs from "./pages/DonateUs/DonateUs";
// import Supporters from "./pages/Supporters/Supporters";
import MemberForm from "./pages/Forms/MemberForm/MemberForm";
import SubmitForm from "./components/Forms/SubmitForm";
// import AdminLogin from "./pages/Admin/AdminLogin/AdminLogin";
// import AdminLandingPage from "./components/Admin/AdminLandingPage/AdminLandingPage";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/join-us" element={<Quote />} />
          <Route path="/team" element={<Team />} />
          <Route path="/our-supporters" element={<Testimonial />} />
          {/* <Route path="/404" element={<Page404 />} />           */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/donate" element={<DonateUs />} />
          {/* <Route path="/our-supporters" element={<Supporters />} /> */}
          <Route path="/member-form" element={<MemberForm />} />
          <Route path="/submit201302983" element={<SubmitForm />} />
          {/* <Route path="/admin" element={<AdminLogin />} />
          <Route path="/adminlandingpage" element={<AdminLandingPage />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
