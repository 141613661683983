import React from "react";

export default function ContactSection() {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
            <div class="row g-5">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <p class="fs-5 fw-bold text-primary">Contact Us</p>
            <h1 class="display-5 mb-5">If You Have Any Query, Please Contact Us</h1>
            <h3 class="display-7 mb-4 far fa-envelope me-2"> Email - animalfeedersteam@gmail.com</h3>
            <h3 class="display-7 mb-4 fa fa-phone-alt me-2"> Phone Number - +918266809969 </h3>
          </div> 
                <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: "450px"}}>
                    <div class="position-relative rounded overflow-hidden h-100">
                        <iframe class="position-relative w-100 h-100"
                        src="https://maps-api-ssl.google.com/maps?hl=en-US&ll=27.904104,78.051395&output=embed&q=+Aligarh,+Uttar+Pradesh+202001,+India+&z=13"
                        title= "iframe" frameborder={"0"} style={{minHeight: "450px" , border:"0"}} allowFullScreen="" aria-hidden="false"
                        tabindex="0" > </iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  );
}

<div class="container-xxl py-5">
<div class="container">
    <div class="row g-5">
        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <p class="fs-5 fw-bold text-primary">Contact Us</p>
            <h1 class="display-5 mb-5">If You Have Any Query, Please Contact Us</h1>
            <h3 class="display-7 mb-4 far fa-envelope me-2"> Email - animalfeedersteam@gmail.com</h3>
            <h3 class="display-7 mb-4 fa fa-phone-alt me-2"> Phone Number - +918266809969 </h3>
          </div> 
    </div>
</div>
</div> 