import { useState } from "react";
import Header from "../../../components/Header/Header";
import PageHeader from "../../../components/Forms/MemberForm/PageHeader";
import FormSection from "../../../components/Forms/MemberForm/MemberForm";
import "./MemberForm.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function MemberForm() {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    servingArea: "",
    gender: "",
    minAge: false,
  });

  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Name",
      label: "Name",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      label: "Email",
      required: true,
    },
    {
      id: 3,
      name: "phone",
      placeholder: "Phone",
      label: "Phone",
      minLength: 10,
      maxLength: 10,
      required: true,
    },
    {
      id: 4,
      name: "address",
      type: "address",
      placeholder: "Address",
      label: "Address",
      required: true,
    },
    {
      id: 5,
      name: "servingArea",
      type: "text",
      placeholder: "Serving Area",
      label: "Serving Area",
      required: true,
    },
    {
      id: 6,
      name: "gender",
      type: "text",
      placeholder: "Gender",
      label: "Gender",
      required: true,
    },
    {
      id: 7,
      name: "minAge",
      type: "number",
      placeholder: "Age",
      label: "Age",
      required: true,
    },
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (values.minAge <= 16) {
      values.minAge = false;
    } else {
      values.minAge = true;
    }

    values.phone = parseInt(values.phone);

    try {
      const apiUrl =
        "https://myapp-5y4cdnnwtq-em.a.run.app/animalfeeders/v1/createUser"; // Replace with your API endpoint

      let userData = JSON.stringify(values);

      const response = await axios.post(apiUrl, userData);
      console.log("---------->>>>>>>>>>>>>", response.status);

      if (response.status === 200) {
        console.log("Data successfully posted to API.", values);

        navigate("/submit201302983", {
          state: {
            pageTitle: "MemberShip Form",
            pageSubTitle: "MemberShip Form",
          },
        });
      } else {
        console.error("Error posting data to API.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Header />
      <PageHeader title="Membership Form" subTitle="Membership Form" />

      <div className="app">
        <form onSubmit={handleSubmit}>
          <h1>Join us as Member</h1>
          {inputs.map((input) => (
            <FormSection
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
          <button className="submitButton">Submit</button>
        </form>
      </div>
    </>
  );
}
